/* General Styles */
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  color: #333;
  background-color: #f4f4f4;
}

/* Login Page */
.login-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('../src//new.jpg');
  background-size: cover;
  background-position: center;
  height: 100vh;
  color: white;
  text-align: center;
}

.login-page h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.login-page form {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
}

.login-page input {
  width: calc(100% - 22px);
  padding: 10px;
  margin: 10px 0;
  border: none;
  border-radius: 4px;
}

.login-page button {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  margin-bottom: 20px; /* Add space below the login button */
}

.login-page .other-buttons {
  display: flex;
  justify-content: center;
}

.login-page .other-buttons button {
  margin: 0 10px; /* Space between the job apply and submit serial number buttons */
}

.login-page button:hover {
  background-color: #0056b3;
}

/* Job Apply Page */
.job-apply-page, .serial-number-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.job-apply-page h1, .serial-number-page h1 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.job-apply-page form, .serial-number-page form {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
}

.job-apply-page input, .serial-number-page input {
  width: calc(100% - 22px);
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.job-apply-page button, .serial-number-page button {
  background-color: #28a745;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 10px;
}

.job-apply-page button:hover, .serial-number-page button:hover {
  background-color: #218838;
}

input[type="date"] {
  cursor: pointer;
}
p {
  /* text-align: center; */
  color: red;
}